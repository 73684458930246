import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import _ from 'lodash'

import apiExternalAdapters from 'api/external-adapters'
import apiReports from 'api/reports'

import { LoadingOverlay, FlexView, Icon, Button } from 'components/common'

import StockTabs from 'containers/layout/StockTabs'

import { AppContext } from 'stores/AppStore'
import { UserContext } from 'stores/UserStore'
import { WarehouseContext } from 'stores/WarehouseStore'

import { FEATURE } from 'utils/constants'
import FilterDisplay from 'containers/common/FilterDisplay'
import FiltersModal from 'containers/common/FiltersModal'
import { FilterContext } from 'stores/FilterStore'
import TableGrouped from 'components/common/TableGrouped'

const formatReports = (reports) => _.chain(reports)
                                    .orderBy('inspectionDate', 'desc')
                                    .orderBy('partNumber', 'asc')
                                    .value()

const ReturnReportsPage = ({ stockKey }) => {
  const { t } = useTranslation()
  const { setPageSubtitle, datasets } = useContext(AppContext)
  const { endUsers, idToken } = useContext(UserContext)
  const { warehouses } = useContext(WarehouseContext)

  const { filteredData: { filteredTableData }, setData } = useContext(FilterContext)

  const stock = useMemo(() => _.find(warehouses, { key: stockKey }), [warehouses, stockKey])

  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (stock) {
      setPageSubtitle(stock?.title + ' - ' + t('Return Reports'))
    }
  }, [stock, setPageSubtitle, t])

  const data = useMemo(() => (
    _.chain(filteredTableData)
      .groupBy((item)=>`${item.id}`)
      .map((value) => ({
        id: value[0].id,
        name: value[0].name,
        customerOrder: value[0].customerOrder,
        customerItem: value[0].customerItem,
        salesOrder: value[0].salesOrder,
        salesItem:  value[0].salesItem,
        inspectionDate: value[0].inspectionDate,
        inspectionType: value[0].inspectionType,
        erpReference: value[0].erpReference,
        materialDescription: value[0].materialDescription,
        nonTraceable: value[0].nonTraceable,
        totalInspected: _.sumBy(value, ({totalInspected}) => Number(totalInspected)),
        totalAccepted: _.sumBy(value, ({totalAccepted}) => Number(totalAccepted)),
        totalRejected: _.sumBy(value, ({totalRejected}) => Number(totalRejected)),
        totalToRepair: _.sumBy(value, ({totalToRepair}) => Number(totalToRepair)),
        canDownload: value[0].isDownloadable,
        children: value || []
      }))
      .orderBy('id', 'desc')
      .value()
  ), [filteredTableData])

  const onDownloadClick = useCallback(
    async (inspection) => {
      try {
        setLoading(true)
        const reportType = inspection?.isModifiedProduct ? FEATURE.MODIFIED_PRODUCTS : FEATURE.RIG_RETURN
        const params = { reportId: inspection.id, reportType: reportType, partNumber: inspection.partNumber }
        const result = await apiExternalAdapters.getReportFromReporter(params, idToken)
        const link = document.createElement('a')
        link.href = result
        link.target = '_blank'
        link.click()
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    },
    [idToken]
  )

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true)
        const list = await apiReports.getRigReturnList(stock?.wid, idToken)
        setData(formatReports(list))
      } catch (error) {
        toast.error(error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [endUsers, datasets, idToken, stock, setData])

  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: t('ID'),
        label: t('ID'),
        customHeaderProps: {
          style: {
            minWidth: '60px',
          },
        },
        Cell: ({ cell: { value, row } }) => (
          <FlexView
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            fontSize="13px"
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 32}px`,
                cursor: row.canExpand ? 'pointer' : 'inherit'
              },
              title: ''
            })}
          >
            {row.canExpand && <Icon name={row.isExpanded ? 'chevron-up' : 'chevron-down'} width="12px" height="12px" margin="0px 4px 0px 8px" color={ row.isExpanded ? 'white' : 'gray'}/>}
            {value === 'null' ? t('Not Found') : value}
          </FlexView>
        ),
        show: true,
        filterModalEnabled: true,
      },
      {
        accessor: 'partNumber',
        Header: t('Part Number'),
        label: t('Part Number'),
        customHeaderProps: {
          style: {
            minWidth: '60px',
          },
        },
        show: true,
        filterModalEnabled: false,
      },
      {
        accessor: 'name',
        Header: t('Name'),
        label: t('Name'),
        customHeaderProps: {
          style: {
            minWidth: '150px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        accessor: 'inspectionType',
        Header: t('Inspection Type'),
        label: t('Inspection Type'),
        customHeaderProps: {
          style: {
            minWidth: '150px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        id: 'inspectionDate',
        Header: t('Return Date'),
        label: t('Return Date'),
        accessor: 'inspectionDate',
        customHeaderProps: {
          style: {
            minWidth: '150px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        accessor: 'erpReference',
        Header: t('MR Reference'),
        label: t('MR Reference'),
        customHeaderProps: {
          style: {
            minWidth: '150px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        label: t('Non Traceable'),
        accessor: 'nonTraceable',
        Header: t('Non Traceable'),
        customHeaderProps: {
          style: {
            minWidth: '100px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        label: t('Inspected'),
        accessor: 'totalInspected',
        Header: t('Inspected'),
        customHeaderProps: {
          style: {
            minWidth: '70px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        label: t('Accepted'),
        accessor: 'totalAccepted',
        Header: t('Accepted'),
        customHeaderProps: {
          style: {
            minWidth: '70px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        label: t('Rejected'),
        accessor: 'totalRejected',
        Header: t('Rejected'),
        customHeaderProps: {
          style: {
            minWidth: '70px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        label: t('To Repair'),
        accessor: 'totalToRepair',
        Header: t('To Repair'),
        customHeaderProps: {
          style: {
            minWidth: '75px',
          },
        },
        show: true,
        filterModalEnabled: true,
      },
      {
        id: 'download',
        Header: t('Download'),
        disableFilters: true,
        customHeaderProps: {
          style: {
            minWidth: '32px',
          },
        },
        Cell: ({ cell: { row } }) => (
          <Button backgroundColor={!row.canExpand ? '#f5f5f5' : row.isExpanded ? '#667EEA' : 'inherit'} onClick={() => onDownloadClick(row.original)}
          alignItems="center" justifyContent="center" width="100%" margin='0' padding='0' disabled={row.canExpand ? !row.original.canDownload : !(row.original.executionStatus ===3)}> 
            <Icon name="download" width="24px" height="24px" color={ row.isExpanded ? 'white' : 'gray' } />
          </Button>
        ),
        show: true,
        filterModalEnabled: false,
      },
    ],
    [t, onDownloadClick ]
  )

  const toggleModal = () => setShowModal((currentState) => !currentState)

  return (
    <FlexView flex='1' position='relative' alignSelf='stretch'>
      <StockTabs stock={stock} />
      <FlexView margin='16px 16px 8px' flexDirection='row' justifyContent='space-between' alignSelf='stretch'>
        <FilterDisplay options={columns} onTagClick={toggleModal} />
        <FlexView flexDirection='row' alignItems='center' justifyContent='flex-end' flex='1'>
          <Icon name='filter' width='28px' height='28px' margin='0px 8px 0px 0px' tooltip={t('Filter')} onClick={toggleModal}/>
        </FlexView>
      </FlexView>
      <TableGrouped columns={columns} data={data} quantityLabel='Items'/>
      <FiltersModal isOpen={showModal} onOutsideClick={toggleModal} options={columns} data={data} />
      <LoadingOverlay visible={loading} />
    </FlexView>
  )
}

export default ReturnReportsPage
